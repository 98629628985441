import { jsPDF } from "jspdf";
import "jspdf-autotable";
import DenominatifPembiayaanListPdf from "@/components/page/denominatif-pembiayaan-list-pdf";
import moment from "moment";
export default {
  name: "DenominatifPembiayaan",
  components: {
    DenominatifPembiayaanListPdf,
  },
  data() {
    return {
      property: {
        animation: {
          denominatif: {
            isLoading: false,
            isDownloadingFile: false,
          },
        },
        listElement: {
          denominatif: {
            downloading: false,
            message: "",
          },
        },
      },
      options: {
        office: [],
        loanAkadType: [],
        export: [],
      },
      title_app: "",
      dataForm: {
        officeCode: "",
        officeName: "",
        loanAkadTypeCode: "",
        timePeriod: "",
        profileSureName: "",
        profileSureNameHandling: "",
        profileSureNameCollection: "",
        startDate: "",
        endDate: "",
        exportType: "",
        exportToExcel: {
          data: [],
          header: [],
        },
      },
      table: {
        header: {
          denominatif: [
            {
              key: "noKontrak",
              label: "No. Kontrak",
            },
            {
              key: "nama",
              label: "nama",
            },
            {
              key: "alamat",
              label: "Alamat",
            },
            {
              key: "noakad",
              label: "No. Akad",
            },
            {
              key: "sektorEkonomi",
              label: "Sektor Ekonomi",
            },
            {
              key: "tglDropping",
              label: "Tgl Dropping",
            },
            {
              key: "jkWaktu",
              label: "Jangka Waktu",
            },
            {
              key: "jthTempo",
              label: "Jatuh Tempo",
            },
            {
              key: "platfon",
              label: "Plafon",
            },
            {
              key: "margin",
              label: "Margin",
            },
            {
              key: "outstdPokok",
              label: "Outstanding Pokok",
            },
            {
              key: "outstdMargin",
              label: "Outstanding dMargin",
            },
            {
              key: "kolektibilitas",
              label: "Kolektibilitas",
            },
            {
              key: "tunggakanPokok",
              label: "tunggakan Pokok",
            },
            {
              key: "tunggakanMargin",
              label: "Tunggakan Margin",
            },
            {
              key: "oaCollection",
              label: "AO Collection",
            },
          ],
        },
        data: {
          denominatif: [],
        },
      },
    };
  },
  methods: {
    async onDownloadFile(fileType) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const payload = {
        fileType: fileType,
        officeCode: this.dataForm.officeCode,
        loanAkadTypeCode: this.dataForm.loanAkadTypeCode,
        profileSurenameHandling: this.dataForm.profileSureNameHandling,
        profileSurenameCollection: this.dataForm.profileSureNameCollection,
        timePeriod: this.dataForm.timePeriod,
      };
      this.property.animation.denominatif.isDownloadingFile = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "report",
          reqUrl: "report/denominatif/files",
          payload: payload,
        });
        if (fileType === "pdf") {
          this.printOutWindow(resp.data);
          return;
        }
        var a = document.createElement("a"),
          url = URL.createObjectURL(resp.data);
        a.href = url;
        a.download = `Denominatif_Pembiayaan.${
          fileType === "xls" ? "xlsx" : "pdf"
        }`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
      } catch (error) {
      } finally {
        this.property.animation.denominatif.isDownloadingFile = false;
      }
    },
    async getAsset() {
      const session = sessionStorage.getItem("@vue-session/asset-bundle");
      this.title_app = JSON.parse(window.atob(session)).companyName;
    },
    exportPdf() {
      const getUserPayloadFromSession = JSON.parse(
        this.decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
      );
      this.$refs.denominatifPembiayaanListPdfRef.onOpenPreview({
        tableData: this.table.data.denominatif,
        letterhead: {
          printDate: `${moment().format("DD-MM-YYYY")} ${moment().format(
            "HH:mm:ss"
          )}`,
          officeName: getUserPayloadFromSession.officeName,
          startDate: this.dataForm.startDate
            ? this.dataForm.startDate
            : `${moment().format("DD-MM-YYYY")} `,
          endDate: this.dataForm.endDate
            ? this.dataForm.endDate
            : `${moment().format("DD-MM-YYYY")} `,
        },
        printBy: `${getUserPayloadFromSession.userName} ${moment().format(
          "DD-MM-YYYY"
        )} ${moment().format("HH:mm:ss")}`,
      });
    },
    preparePeriode() {
      if (this.table.data.denominatif !== []) {
        this.dataForm.startDate = `${new Date(
          this.table.data.denominatif[0].createdDate
        ).getDate()} ${
          this.property.accessories.day[
            new Date(this.table.data.denominatif[0].createdDate).getMonth()
          ]
        } ${new Date(
          this.table.data.denominatif[0].createdDate
        ).getFullYear()}`;

        this.dataForm.endDate = `${new Date(
          this.table.data.denominatif[
            this.table.data.denominatif.length - 1
          ].createdDate
        ).getDate()} ${
          this.property.accessories.day[
            new Date(
              this.table.data.denominatif[
                this.table.data.denominatif.length - 1
              ].createdDate
            ).getMonth()
          ]
        } ${new Date(
          this.table.data.denominatif[
            this.table.data.denominatif.length - 1
          ].createdDate
        ).getFullYear()}`;
      }
    },
    convertCurrencyDenominatif(val) {
      var harga = val
          .toString()
          .split("")
          .reverse()
          .join(""),
        hargaRibuan = harga.match(/\d{1,3}/g);
      hargaRibuan = hargaRibuan
        .join("")
        .split("")
        .reverse()
        .join("");
      return hargaRibuan;
    },
    setupDataExportExcel() {
      this.dataForm.exportToExcel.data = [];
      this.dataForm.exportToExcel.header = [];
      this.table.header.denominatif.map((index) => {
        const field = index.key;
        const label = `${index.label}`;
        this.dataForm.exportToExcel.header.push({ field, label });
      });
      this.table.data.denominatif.map((index) => {
        this.dataForm.exportToExcel.data.push({
          noKontrak: index.noKontrak,
          nama: index.nama,
          alamat: index.alamat,
          noakad: index.noakad,
          sektorEkonomi: index.sektorEkonomi,
          tglDropping: index.tglDropping,
          jkWaktu: index.jkWaktu,
          jthTempo: index.jthTempo,
          platfon: index.platfon ? index.platfon : 0,
          margin: index.margin ? index.margin : 0,
          outstdPokok: index.outstdPokok ? index.outstdPokok : 0,
          outstdMargin: index.outstdMargin ? index.outstdMargin : 0,
          kolektibilitas: index.kolektibilitas,
          tunggakanPokok: index.tunggakanPokok ? index.tunggakanPokok : 0,
          tunggakanMargin: index.tunggakanMargin ? index.tunggakanMargin : 0,
          oaCollection: index.oaCollection,
        });
      });
    },
    async getReferenceOffice() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            url: "office",
            params: {
              officeName: "",
              page: 0,
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          this.options.office = [{ value: "", text: "-- Semua Cabang --" }];
          resp.data.data.content.map((index) => {
            const text = index.officeName;
            const value = index.officeCode;
            this.options.office.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getReferenceLoanAkadType() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_LOAN", {
          url: "loan-akad-type",
        });
        if (resp.data.code === "SUCCESS") {
          this.options.loanAkadType = [
            { value: "", text: "-- Semua Produk --" },
          ];
          resp.data.data.map((index) => {
            const text = index.loanAkadTypeName;
            const value = index.loanAkadTypeCode;
            this.options.loanAkadType.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getReportDenominatif() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      this.$buefy.dialog.confirm({
        message: "Proses Data Ini ?",
        type: "is-success",
        onConfirm: async () => {
          this.property.animation.denominatif.isLoading = true;
          this.property.listElement.denominatif.downloading = true;
          this.table.data.denominatif = [];
          try {
            const resp = await this.$store.dispatch({
              type: "GET_LIST_DATA",
              endPoint: "report",
              reqUrl: "report/denominatif",
              payload: {
                officeCode: this.dataForm.officeCode,
                loanAkadTypeCode: this.dataForm.loanAkadTypeCode,
                profileSurenameHandling: this.dataForm.profileSureNameHandling,
                profileSurenameCollection: this.dataForm
                  .profileSureNameCollection,
                timePeriod: this.dataForm.timePeriod,
              },
            });
            if (resp.data.code === "SUCCESS") {
              setTimeout(() => {
                this.table.data.denominatif = resp.data.data;
                this.dataForm.officeName = resp.data.data.officeName;
                this.preparePeriode();
                this.setupDataExportExcel();
              }, 1000);
            } else {
              this.property.listElement.denominatif.message = resp.data.message;
            }
          } catch (error) {
            this.table.data.denominatif = [];
            this.property.listElement.denominatif.message = error.response
              ? `Gagal Mengunduh Data : ${error.response.statusText}`
              : "Terjadi Kesalahan";
          } finally {
            setTimeout(() => {
              this.property.animation.denominatif.isLoading = false;
              this.property.listElement.denominatif.downloading = false;
            }, 1000);
          }
        },
      });
    },
    chargeReferenceExportType() {
      this.options.export = [
        { value: "", text: "-- Pilih --" },
        { value: "excel", text: "Excel" },
      ];
    },
  },
  mounted() {
    this.getAsset();
    this.getReferenceOffice();
    this.getReferenceLoanAkadType();
    this.property.listElement.denominatif.message = "DATA NOT FOUND";
    this.chargeReferenceExportType();
  },
};
